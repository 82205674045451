import React from "react"
import { Grid } from "@material-ui/core"
import * as S from "./footer.styles"
import ContactBar from "../../layouts/ContactBar/ContactBar"

const Footer = ({ className, hideFooterContactForm }) => {
  const aboutItems = [
    { url: "/about/team/", title: "Our Team" },
    { url: "/about/partners/", title: "Partners" },
    { url: "/about/newsroom/", title: "Newsroom" },
  ]

  const platformItems = [
    { url: "/platforms/colocation/", title: "Colocation" },
    { url: "/platforms/cloud/", title: "Cloud" },
    { url: "/platforms/network/", title: "Network" },
    {
      url: "/platforms/high-performance-compute/",
      title: "High-Performance Compute",
    },
    { url: "/platforms/storage/", title: "Storage" },
    { url: "/platforms/24-7-noc/", title: "24/7 NOC" },
  ]

  const solutionsItems = [
    {
      url: "/solutions/disaster-recovery-aas/",
      title: "Disaster Recovery aaS",
    },
    { url: "/solutions/cloud-connect/", title: "Cloud Connect" },
    { url: "/solutions/data-security/", title: "Data Security" },
    {
      url: "/solutions/it-transformation-aas/",
      title: "IT Transformation aaS",
    },
    { url: "/solutions/modular-data-centers/", title: "Modular Data Centers" },
  ]

  const esgItems = [
    { url: "/esg/purpose-driven-culture/", title: "Purpose-Driven Culture" },
    { url: "/esg/sustainably-modular/", title: "Sustainably Modular" },
  ]

  const locationItems = [
    { url: "/locations/miami/", title: "Miami" },
    { url: "/locations/pittsburgh/", title: "Pittsburgh" },
  ]

  return (
    <S.Footer className={className}>
      {!hideFooterContactForm && <ContactBar />}

      <S.FooterContainer className="sitemap">
        <Grid container spacing={3}>
          <Grid item md={2} sm={2} xs={12} className="logo">
            <div className="brand">
              <a
                href="https://imhpc.ai/?utm_source=imcritical.com"
                target="_blank"
                aria-label="iM HPC AI"
              >
                <S.IMHPCACILogo />
              </a>
              <p>
                Visit{" "}
                <a
                  href="https://imhpc.ai/?utm_source=imcritical.com"
                  target="_blank"
                >
                  imhpc.ai
                </a>{" "}
                to learn about our dedicated HPCaaS Nodes and MLOPS Consulting
                Services
              </p>
            </div>

            <div className="brand">
              <a
                href="https://imdatacenters.com/?utm_source=imcritical.com"
                target="_blank"
                aria-label="iM Data Centers"
              >
                <S.IMDataCentersLogo />
              </a>
              <p>
                Visit{" "}
                <a
                  href="https://imdatacenters.com/?utm_source=imcritical.com"
                  target="_blank"
                >
                  imdatacenters.com
                </a>{" "}
                to view our Factory-Built Modular Data Centers
              </p>
            </div>
          </Grid>
          <Grid container item md={10} sm={10} xs={12}>
            <Grid item md={3} sm={6} xs={12}>
              <h6 className="section">
                <a href="/platforms/">Platforms</a>
              </h6>
              <ul className="nav">
                {platformItems.map((item, index) => (
                  <li key={`item${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <h6 className="section">
                <a href="/solutions/">Solutions</a>
              </h6>
              <ul className="nav">
                {solutionsItems.map((item, index) => (
                  <li key={`item${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <h6 className="section">
                <a href="/esg/">ESG</a>
              </h6>
              <ul className="nav">
                {esgItems.map((item, index) => (
                  <li key={`item${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
              <h6 className="section">
                <a href="/locations/">Locations</a>
              </h6>
              <ul className="nav">
                {locationItems.map((item, index) => (
                  <li key={`item${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <h6 className="section">
                <a href="/about/">About</a>
              </h6>
              <ul className="nav">
                {aboutItems.map((item, index) => (
                  <li key={`item${index}`}>
                    <a href={item.url}>{item.title}</a>
                  </li>
                ))}
              </ul>
              <h6 className="section">
                <a href="/contact/">Contact</a>
              </h6>
              <h6 className="section">
                <a href="https://imcritical.com" target="_blank">
                  Portal
                </a>
              </h6>
            </Grid>
          </Grid>
        </Grid>
      </S.FooterContainer>

      <S.FooterContainer className="legal">
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className="privacy">
            <a href="/privacy/">Privacy Policy</a>
          </Grid>

          <Grid item md={6} xs={12} className="copyright">
            &copy; {new Date().getFullYear()} iMCritical
          </Grid>
        </Grid>
      </S.FooterContainer>
    </S.Footer>
  )
}

export default Footer
