import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import * as S from "./contact-form.styles"
import { useForm } from "react-hook-form"
import { contactFormApi } from "../../apis/apis"
import { Helmet } from "react-helmet"

const ContactForm = () => {

    const { register, handleSubmit, errors, control, ref } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
    })

    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState("")

    const onSubmit = async data => {
        setIsLoading(true)
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6Lf92d8eAAAAADY4c_6id012TZhFl5jTY7uCyBtD', {action: 'submit'}).then(function(token) {
                const form = new FormData()
                form.append("first_name", data.first_name)
                form.append("last_name", data.last_name)
                form.append("email", data.email)
                form.append("phone", data.phone)
                form.append("message", data.message)
                form.append("_wpcf7_recaptcha_response", token)
            
                contactFormApi
                .post("/1121/feedback", form, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then(response => {
                    setIsLoading(false)
                    if (response.data) {
                        setSuccessMessage(response.data.message)
                        window.dataLayer.push({'event':'contact_submit'})
                    }
                })
            })
        })
      }

    return (
        <>
        <Helmet>
            <script src="https://www.google.com/recaptcha/api.js?render=6Lf92d8eAAAAADY4c_6id012TZhFl5jTY7uCyBtD"></script>
        </Helmet>
        {!successMessage ? (
            <S.Form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <input type="text" { ...register("first_name", { required: true }) } required placeholder="First Name" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <input type="text" { ...register("last_name", { required: true }) } required placeholder="Last Name" />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                        <input type="email" { ...register("email", { required: true }) } required placeholder="Email Address" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <input type="text" { ...register("phone", { required: true }) } required placeholder="Phone Number" />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                        <textarea name="message" placeholder="How can we help?" { ...register("message", { required: true }) } required></textarea>
                    </Grid>
                </Grid>

                <button type="submit">Submit</button>
            </S.Form>
        ) : (
            <>
              {successMessage}
            </>
        )}
        </>
    )
}

export default ContactForm